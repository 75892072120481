:root {
        --bs-primary-rgb: 29,93,175;
}
a {color: #1d5daf;}
.nav-line-tabs .nav-item .nav-link.active, .nav-line-tabs .nav-item .nav-link:hover:not(.disabled), .nav-line-tabs .nav-item.show .nav-link {border-color: #1d5daf !important;}
.badge-light-primary,.text-active-primary.active, .text-hover-primary:hover, .menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active), .menu-state-primary .menu-item.hover>.menu-link:not(.disabled):not(.active),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title, .menu-state-primary .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-title, .nav-link,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active), .menu-state-bg-light-primary .menu-item.hover>.menu-link:not(.disabled):not(.active), .accordion-button:not(.collapsed),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title, .menu-state-bg-light-primary .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-title
{color: #1d5daf !important;}
.btn.btn-primary, .page-item.active .page-link, .btn-check:active+.btn.btn-active-primary, .btn-check:checked+.btn.btn-active-primary, .btn.btn-active-primary.active, .btn.btn-active-primary.show, .btn.btn-active-primary:active:not(.btn-active), .btn.btn-active-primary:focus:not(.btn-active), .btn.btn-active-primary:hover:not(.btn-active), .show>.btn.btn-active-primary
{background-color: #1d5daf !important;}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .page-item.active .page-link, .btn.btn-primary,
.btn-check:active+.btn.btn-active-primary, .btn-check:checked+.btn.btn-active-primary, .btn.btn-active-primary.active, .btn.btn-active-primary.show, .btn.btn-active-primary:active:not(.btn-active), .btn.btn-active-primary:focus:not(.btn-active), .btn.btn-active-primary:hover:not(.btn-active), .show>.btn.btn-active-primary
{color: #fff !important;}
.btn-primary {background-color: #1d5daf; border-color: #1d5daf;}
.btn-check:active+.btn.btn-primary, .btn-check:checked+.btn.btn-primary, .btn.btn-primary.active, .btn.btn-primary.show, .btn.btn-primary:active:not(.btn-active), .btn.btn-primary:focus:not(.btn-active), .btn.btn-primary:hover:not(.btn-active), .show>.btn.btn-primary
{background-color: #1d5daf99!important; border-color: #1d5daf99!important;}
.aside .menu>.menu-item>.menu-link:hover .menu-icon {background-color: rgba(255,255,255,.2);}
.aside .menu>.menu-item>.menu-link.active .menu-icon {background-color: #fff;}

.text-white {color: #fff!important;}
#kt_aside_logo img, #mobile-logo img {border-radius: 0.475rem;}
.timeline-label .timeline-label {width: 75px;}
.timeline-label:before {left: 90px;}
.timeline-label .timeline-badge {margin-left: 0.6rem;}
#sponsors .sponsor-logo {height: 90px; width: 150px;}
#sponsors .sponsor-logo img {max-height: 50px; max-width: 120px;}
.sponsor-logo img {min-height: 135px;max-width: 100%;}
#sponsors a {display: block; cursor: pointer;}
.select2-container--bootstrap5 .select2-selection--single {border: none !important;}
.text-facebook-color {color: #4267B2;}
.text-linkedin-color {color: #0e76a8;}
.text-twitter-color {color: #1DA1F2;}
.text-google-plus-color {color: #db4a39;}
.badge i.bi {color: #fff;font-size: .85rem!important;}
.blink {animation: blink 1s ease-in-out infinite;}
.aside-nav {overflow: hidden;height: calc( 100vh - 150px);}
.aside-nav .menu {overflow: auto;justify-content: flex-start;}
.aside-nav,
.aside-nav .menu,
.aside-nav::-webkit-scrollbar,
.aside-nav .menu::-webkit-scrollbar {scrollbar-width:none;scrollbar-color: #1d5daf transparent!important;}
.aside-nav .menu::-webkit-scrollbar-track {box-shadow: inset 0 0 6px #1d5daf!important;}
.aside-nav .menu::-webkit-scrollbar-thumb {background-color: #1d5daf!important;outline: 1px solid #1d5daf!important;}
address{font-size: 20px;white-space: pre-wrap;}
#kt_modal_1 #sponsor_web_link_section #sponsor_web_txt,
#kt_modal_1 #sponsor_email_section #sponsor_email_txt,
#kt_modal_1 #sponsor_description {word-wrap: break-word;}
.modal-dialog .sponsor-logo img {min-height: unset;min-width: unset;}
.sponsor-name, .page .nav .nav-item .nav-link {cursor: pointer;}
@keyframes blink {50% {opacity: .5;}100% {opacity: 1;}}

.header .page-title {display: block!important;}
.flex-grow-1.speaker-details{max-width: 75%;}

@media (max-width: 767px){
.timeline-label .timeline-label {width: 70px;}
.timeline-label:before {left: 80px;}
.timeline-label .timeline-badge {margin-left: 0.3rem;}
}
.agenda-card-details i{margin-right:5px;}
#sponsors .sponsor-logo{justify-self:center;height:auto;}
.spinner-container {width: 100%;min-height: 100%;position: fixed;z-index: 99;background-color: #000000de;}
.loading-spinner {top: 40%;left: calc(50% - 50px);position: absolute;}
.spinner-container .loader-text {position: absolute;top: 50%;color: #FFF;font-size: 22px;width: 100%;text-align: center;}
.spinner-container .loader-text p {margin: 0;}
.sync-active {animation: spinner 1.5s linear infinite;}
button#sync_menu_item {background: transparent;border: 0;padding: 0 !important;}